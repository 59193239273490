<template>
  <div id="category-products-name">
    <validation-observer ref="category-products-name-observer" v-slot="{ valid }" slim>
      <b-table
        ref="category-products-name-table"
        :busy="categoryLoading || productsLoading"
        :current-page="page"
        :fields="categoryProductsFields"
        :items="products.edited"
        :per-page="numberOfItemsPerPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative table-dropdown-action"
        hover
        primary-key="id"
        responsive
        show-empty
      >
        <!-- LOADING -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <!-- EMPTY -->
        <template #empty>
          <div class="text-center text-primary my-2">
            <b-overlay :show="categoryLoading || productsLoading">
              <b-button v-if="$can('OPERATOR_CATEGORY_ADD')" id="category-product-name-add" size="md" variant="outline-primary" @click="addProductName()">
                <span class="text-nowrap">
                  <feather-icon class="mr-25" icon="PlusIcon" />
                  {{ $t('category.product_name.add_new_name') }}
                </span>
              </b-button>
            </b-overlay>
          </div>
        </template>
        <!-- TOP ROW -->
        <template v-if="product" #top-row>
          <!-- CELL -->
          <!-- new product -->
          <b-td class="top-row-unerline">
            <app-form-text-input-itn
              v-model="product.name"
              :label="$t('category.product_name.name')"
              :languages="$store.state.auth.activelang"
              :loading="categoryLoading || productsLoading"
              :show-label="false"
              form-name="category-products-name-new"
              tool-tip-errors
            />
          </b-td>
          <!-- action -->
          <b-td align="right">
            <app-data-table-actions
              :show-cancel="true"
              :show-dropdown="false"
              :show-save="valid"
              table-name="category-products-name-new"
              @cancel="clearProductName()"
              @save="saveProductName(product)"
            />
          </b-td>
        </template>
        <!-- CELL -->
        <!-- name -->
        <template #cell(name)="data">
          <app-form-text-input-itn
            v-model="data.item.name"
            :disabled="products.touched && products.touched !== data.item.id"
            :form-name="`category-products-name-${data.item.id}`"
            :label="$t('category.product_name.name')"
            :languages="$store.state.auth.activelang"
            :loading="categoryLoading || productsLoading"
            :show-label="false"
            tool-tip-errors
            @input-text="products.touched = data.item.id"
          />
        </template>
        <!-- action -->
        <template #cell(actions)="data">
          <div v-show="products.touched === data.item.id" align="right">
            <app-data-table-actions
              :show-cancel="true"
              :show-dropdown="false"
              :show-save="valid"
              table-name="category-products-name"
              @cancel="clearProductName()"
              @save="saveProductName(data.item)"
            />
          </div>
          <app-data-table-actions
            v-show="!products.touched && !product"
            :show-add="$can('OPERATOR_CATEGORY_ADD')"
            :show-delete="$can('OPERATOR_CATEGORY_DELETE')"
            :show-duplicate="$can('OPERATOR_CATEGORY_ADD')"
            :show-edit="false"
            :show-show="false"
            table-name="category-products-name"
            @add="addProductName()"
            @delete="deleteProductName(data.item.id)"
            @duplicate="duplicateProductName(data.item)"
          />
        </template>
      </b-table>
      <!--PAGINATION-->
      <div class="mx-2 mb-2">
        <app-data-table-bottom
          :page="page"
          :per-page="numberOfItemsPerPage"
          :total-count="totalItems"
          table-name="category-products-name"
          @pagination="page = $event"
        />
      </div>
    </validation-observer>
  </div>
</template>

<script>
import {
  deleteCategoryProductNameRequest,
  fetchCategoryProductNameRequest,
  patchCategoryProductNameRequest,
  postCategoryProductNameRequest,
} from '@/request/globalApi/requests/categoryRequests'

import { mapState } from 'vuex'

import AppDataTableBottom from '@/components/AppDataTableBottom.vue'
import AppFormTextInputItn from '@/components/AppFormTextInputItn.vue'
import AppDataTableActions from '@/components/AppDataTableActions.vue'

export default {
  name: 'CategoryProducts',

  components: {
    AppDataTableBottom,
    AppFormTextInputItn,
    AppDataTableActions,
  },

  props: {
    categoryLoading: {
      type: Boolean,
      required: true,
    },
    categoryId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      products: { edited: [], old: [], touched: null },
      product: null,
      productsLoading: false,

      numberOfItemsPerPage: 5,
      page: 1,
      sortBy: 'name',
      isSortDirDesc: false,
    }
  },

  computed: {
    ...mapState('appConfig', ['lang', 'dataTable']),

    categoryProductsFields() {
      return [
        { key: 'name', label: this.$t('category.product_name.name'), sortable: true },
        { key: 'actions', label: this.$tc('action.title', 2), thClass: 'text-right', tdClass: 'text-right' },
      ]
    },

    totalItems() {
      return this.products.edited ? this.products.edited.length : 0
    },
  },

  mounted() {
    this.getProductName()
  },

  methods: {
    getProductName() {
      this.productsLoading = true
      fetchCategoryProductNameRequest(this.categoryId, { getAllItems: true })
        .then(response => {
          this.products.edited = response.data.items
          this.products.old = this._cloneDeep(this.products.edited)
        })
        .finally(() => {
          this.productsLoading = false
        })
    },

    saveProductName(product) {
      if (product.id) {
        patchCategoryProductNameRequest(this.categoryId, product)
          .then(() => {
            this.getProductName()
          })
          .then(() => {
            this.clearProductName()
          })
      } else {
        postCategoryProductNameRequest(this.categoryId, product)
          .then(() => {
            this.getProductName()
          })
          .then(() => {
            this.clearProductName()
          })
      }
    },

    addProductName() {
      this.products.touched = '0'
      this.product = { id: null, name: {} }
    },

    duplicateProductName(productToCopy) {
      this.product = this._cloneDeep(productToCopy)
      this.product.id = null
      Object.keys(this.product.name).forEach(lang => {
        this.product.name[lang] += '_copy'
      })
    },

    deleteProductName(id) {
      deleteCategoryProductNameRequest(this.categoryId, id).then(response => {
        response && this.getProductName()
      })
    },

    clearProductName() {
      this.product = null
      this.products.touched = null
      this.products.edited = this._cloneDeep(this.products.old)
      this.$refs['category-products-name-observer'].reset()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 65px;
}
.top-row-unerline {
  border-bottom: 2px solid $primary !important;
}
</style>
