<template>
  <section>
    <b-card no-body>
      <h3 class="m-2">{{ $t('category.main_informations') }}</h3>
      <validation-observer ref="categoryRules" tag="form" @submit.prevent="saveCategory()">
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" lg="7">
              <app-form-text-input-itn
                v-model="categoryBlank.name"
                :label="$t('category.name')"
                :languages="$store.state.auth.activelang"
                form-name="categories"
              />
              <b-row>
                <b-col cols="12" lg="8">
                  <app-form-text-area-itn
                    v-model="categoryBlank.description"
                    :label="$t('category.description')"
                    :languages="$store.state.auth.activelang"
                    :max-rows="7"
                    :rows="7"
                    form-name="categories"
                    no-resize
                  />
                </b-col>
                <b-col cols="12" lg="4">
                  <b-form-group :label="$t('category.icon.title')" class="mt-1" label-for="category-icon">
                    <b-overlay :show="dropZoneLoading" spinner-medium>
                      <app-drop-zone
                        v-if="category"
                        id="category-icon"
                        ref="categoryIcon"
                        :disabled="dropZoneLoading"
                        :options="dropzoneOptions"
                        class="dropzone-category"
                        @vdropzone-mounted="dropzoneMounted(category.iconFileUrl, 'categoryIcon', 0, 'iconUploaded')"
                        @vdropzone-queue-complete="iconUploaded = true"
                        @vdropzone-removed-file="removeCategoryIcon()"
                        @vdropzone-file-added="sendCategoryIcon($event)"
                      />
                    </b-overlay>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" lg="5">
              <b-form-group :label="$t('category.parent_category')" class="mt-1" label-for="parentCategory">
                <div class="border rounded category-view-tree hide-scroll">
                  <categories-tree :current-parent="[categoryBlank.parent]" @category-selected="getCategorySelected" />
                </div>
              </b-form-group>
              <span class="mb-2">{{ $t('common.note') }} {{ $t('category.parent_category_note') }}</span>
            </b-col>
          </b-row>
          <h3 class="my-2">{{ $t('category.seo') }}</h3>
          <b-row>
            <b-col cols="7">
              <app-form-text-input-itn
                v-model="categoryBlank.seoMetaTitle"
                :label="$t('category.meta_title')"
                :languages="$store.state.auth.activelang"
                form-name="categories"
              />
            </b-col>
            <b-col cols="7">
              <app-form-text-area-itn
                v-model="categoryBlank.seoMetaDescription"
                :label="$t('category.meta_description')"
                :languages="$store.state.auth.activelang"
                :max-rows="5"
                :rows="5"
                form-name="categories"
                no-resize
              />
            </b-col>
          </b-row>
        </div>
        <h3 class="m-2">{{ $tc('category.product_name.title', 0) }}</h3>
        <b-row>
          <b-col cols="12">
            <category-products v-if="category" :category-id="category.id" :category-loading="false" class="mt-2" />
          </b-col>
        </b-row>
        <div class="mx-2 mb-2">
          <b-row align-h="end">
            <b-col cols="12" lg="4">
              <app-form-actions :show-reset="false" form-name="categories" @cancel="$router.push({ name: 'categories' })" />
            </b-col>
          </b-row>
        </div>
      </validation-observer>
    </b-card>
  </section>
</template>

<script>
import CategoriesTree from '@/views/category/CategoriesTree.vue'
import CategoryProducts from '@/views/category/CategoryProducts.vue'

import AppFormTextInputItn from '@/components/AppFormTextInputItn.vue'
import AppFormTextAreaItn from '@/components/AppFormTextAreaItn.vue'
import AppFormActions from '@/components/AppFormActions.vue'

import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import AppDropZone from '@/components/AppDropZone.vue'
import imgUpload from '@/helpers/imgUpload'

import { postFileRequest } from '@/request/globalApi/requests/fileRequests'
import { fetchCategoryRequest, patchCategoryRequest } from '@/request/globalApi/requests/categoryRequests'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'CategoryEdit',

  components: {
    CategoriesTree,
    CategoryProducts,
    AppFormTextInputItn,
    AppFormTextAreaItn,
    AppFormActions,
    AppDropZone,
  },
  mixins: [imgUpload],
  data() {
    return {
      fileCount: 0,
      currentLanguage: '',
      iconUploaded: true,
      dropZoneLoading: false,
      dropzoneOptions: {
        autoProcessQueue: true,
        url: 'https://localhost',
        dictDefaultMessage: this.$t('category.icon.upload'),
        thumbnailWidth: 125,
        thumbnailHeight: 125,
        maxFiles: 1,
        maxFilesize: 2000000,
        acceptedFiles: '',
        addRemoveLinks: true,
        clickable: true,
      },
      category: null,
      categoryBlank: { name: null },
    }
  },
  computed: {
    userLang() {
      return this.$store.state.appConfig.lang
    },
  },
  mounted() {
    this.APIFetchCategory()
  },
  methods: {
    APIFetchCategory() {
      fetchCategoryRequest(this.$route.params.category_id)
        .then(response => {
          const { category, parentId } = response.data
          this.category = category
          this.setTranslations(parentId)
        })
        .catch(() => {
          this.toaster(this.$t('api.alert.category.cannot_fetch'), 'danger', 'AlertTriangleIcon')
        })
    },
    setTranslations(parentId) {
      this.category.name.currentLanguage = this.userLang
      this.category.description.currentLanguage = this.userLang
      this.category.seoMetaTitle.currentLanguage = this.userLang
      this.category.seoMetaDescription.currentLanguage = this.userLang
      this.category.active = true
      this.category.parent = parentId

      this.categoryBlank = JSON.parse(JSON.stringify(this.category))
    },
    getCategorySelected(cats) {
      this.categoryBlank.parent = cats[0]?.id || null
    },
    saveCategory() {
      const categoryToSend = JSON.parse(JSON.stringify(this.categoryBlank))

      delete categoryToSend.name.currentLanguage
      delete categoryToSend.description.currentLanguage
      delete categoryToSend.seoMetaTitle.currentLanguage
      delete categoryToSend.seoMetaDescription.currentLanguage

      Object.keys(categoryToSend.name).forEach(k => categoryToSend.name[k] == null && delete categoryToSend.name[k])
      Object.keys(categoryToSend.description).forEach(k => categoryToSend.description[k] == null && delete categoryToSend.description[k])
      Object.keys(categoryToSend.seoMetaTitle).forEach(k => categoryToSend.seoMetaTitle[k] == null && delete categoryToSend.seoMetaTitle[k])
      Object.keys(categoryToSend.seoMetaDescription).forEach(k => categoryToSend.seoMetaDescription[k] == null && delete categoryToSend.seoMetaDescription[k])

      categoryToSend.id = this.categoryBlank.id
      patchCategoryRequest(categoryToSend).then(() => {
        this.$router.push({ name: 'categories' })
      })
    },
    removeCategoryIcon() {
      this.categoryBlank.categoryIconId = null
      this.categoryBlank.deleteOldCategoryIcon = true
    },
    sendCategoryIcon(file) {
      if (file.size > this.dropzoneOptions.maxFilesize) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('file.max_file_size_reached'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        this.$refs.categoryIcon.removeFile(file)
        return
      }

      this.dropZoneLoading = true
      this.iconUploaded = false
      const formData = new FormData()
      formData.append('temporary_file', file)
      postFileRequest(formData)
        .then(response => {
          this.categoryBlank.categoryIconId = response.data.id
          this.categoryBlank.deleteOldCategoryIcon = false
        })
        .finally(() => {
          this.iconUploaded = true
          this.dropZoneLoading = false
        })
    },
  },
}
</script>
<style scoped>
.category-view-tree {
  height: 267px;
  max-height: 267px;
  overflow: auto;
}

.dropzone-category {
  height: 181px;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}
</style>
